<template>
    <section id="who-we-are" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
        <b-container>
            <SectionHeader :header-text="mainTitle"/>
            <div class="text-container">
                <p class="bolder-medium-text">{{rightSideText}}</p>
                <p class="small-text text-right" v-html="leftSideText"></p>
            </div>
            <div class="imgContainer" v-if="imgUrls !== null">
                <img :src="imgUrls.mainImage" alt=""/>
                <img :src="imgUrls.midImage" alt=""/>
                <img :src="imgUrls.smallImage" alt=""/>
            </div>
        </b-container>
    </section>
</template>
<script>

import SectionHeader from "../global/SectionHeader/sectionHeader";
import {getAboutPageWithThreeImgContent} from "../../assets/js/services";

export default {
    name: 'WhoWeAre',
    components: {SectionHeader},
    data() {
        return {
            mainTitle: '',
            rightSideText: '',
            leftSideText: '',
            imgUrls: {},
        }
    },
    async created() {
        const response = await getAboutPageWithThreeImgContent()
        const data = response.aboutPageWithThreeImgs[0]
        this.mainTitle = data.mainTitle
        this.rightSideText = data.rightSideText
        this.leftSideText = data.leftSideText.replace(/\n/g, "<br/>")
        this.imgUrls = {
            mainImage: data.sectionImage[0].url,
            midImage: data.sectionImage[1].url,
            smallImage: data.sectionImage[2].url
        }

    }
}
</script>
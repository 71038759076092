<template>
    <div class="privacy-policy">
        <Navbar :nav-links="navLinks" button-text="Contact us" button-reference="contactUs" />
        <b-container>
            <SectionHeader :header-text="mainTitle"/>
            <div class="intro-text-container">
                <p class="lastUpdated">Last Updated: {{$moment(lastUpdated).format('MMM DD, YYYY')}}</p>
                <p class="intro-text">{{ introText }}</p>
                <div class="privacy-policy-section" v-for="(section, index) in privacyPolicySections" :key="index">
                    <p class="title">{{index+1}}. {{section.title}}</p>
                    <div v-html="section.content.html"></div>
                </div>
            </div>
        </b-container>
        <div id="contactUs">
            <Footer hasTitle/>
        </div>
    </div>
</template>

<script>
import SectionHeader from "../global/SectionHeader/sectionHeader";
import {getPrivacyPolicyContent} from "../../assets/js/services";
import Footer from "../Footer/footer";
import Navbar from "../Navbar/navbar";
export default {
    name: 'PrivacyPolicy',
    components: { Footer, SectionHeader, Navbar},
    data(){
        return{
            mainTitle: '',
            introText: '',
            privacyPolicySections: [],
            lastUpdated: '',
            navLinks: [
                {
                    text: 'Who are we',
                    url: '/#who-we-are'
                },
                {
                    text: 'What we do',
                    url: '/#what-we-do'
                },
                {
                    text: 'We work with',
                    url: '/#we-work-with'
                },
                {
                    text: 'Clients',
                    url: '/#clients-and-partners'
                },
                {
                    text: 'Offers',
                    url: '/#offers'
                },
                {
                    text: 'Coworking',
                    url: '/coworking'
                }
            ]
        }
    },
    async created(){
        const response = await getPrivacyPolicyContent()
        const data = response.privacyPolicies[0]

        this.mainTitle = data.title
        this.introText = data.introText
        this.privacyPolicySections = data.contentSections
        const lastUpdatedDate = Date.parse(data.updatedAt)
        this.lastUpdated = lastUpdatedDate
    }
}

</script>
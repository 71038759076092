<template>
    <section id="offers" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
        <b-container>
            <SectionHeader :header-text="mainTitle"/>
            <div class="content">
                <div class="text-container">
                    <p class="bolder-medium-text">{{mainDescription}}</p>
                </div>
            </div>
            <div class="custom-navigation-container" v-if="contentContainers.length !== 0">
                <div class="navigation">
                    <div class="navigation-link" :class="selectedNav === 1? 'active':'inactive'" @click="setSelectedNav(1)">
                        <p class="link-title">{{contentContainers[0].contentTitle}}</p>
                        <p>01</p>
                    </div>
                    <div class="navigation-link" :class="selectedNav === 2? 'active':'inactive'" @click="setSelectedNav(2)">
                        <p class="link-title">{{contentContainers[1].contentTitle}}</p>
                        <p>02</p>
                    </div>
                    <div class="navigation-link" :class="selectedNav === 3? 'active':'inactive'" @click="setSelectedNav(3)">
                        <p class="link-title">{{contentContainers[2].contentTitle}}</p>
                        <p>03</p>
                    </div>
                </div>
                <FirstNavContentStyle v-if="selectedNav === 1" :content-boxes="contentContainers[0].contentBoxes" key="content-1"/>
                <SecondContentStyle v-if="selectedNav === 2" :content-boxes="contentContainers[1].contentBoxes" key="content-2" />
                <SecondContentStyle v-if="selectedNav === 3" :content-boxes="contentContainers[2].contentBoxes" key="content-3"/>
            </div>
        </b-container>
    </section>
</template>
<script>

import SectionHeader from "../global/SectionHeader/sectionHeader";
import FirstNavContentStyle from "./firstNavContentStyle";
import SecondContentStyle from "./secondNavContentStyle";
import {getContentWithNavigation} from "../../assets/js/services";

export default {
    name: 'Offers',
    components: {SecondContentStyle, FirstNavContentStyle, SectionHeader},
    data() {
        return {
            selectedNav: 1,
            mainTitle: '',
            mainDescription: '',
            contentContainers: []
        }
    },
    async created(){
        const response = await getContentWithNavigation()
        const data = response.contentWithNavigations[0]

        this.mainTitle = data.mainTitle
        this.mainDescription = data.description
        this.contentContainers = data.contentContainers
    },
    methods: {
        setSelectedNav(navId){
            this.selectedNav = navId
            this.rerenderComponent()
        },
        rerenderComponent() {
            let tempArray = this.contentContainers
            this.contentContainers = []
            this.$nextTick(()=>{
                this.contentContainers = tempArray
            })
        }
    }
}
</script>

<template>
    <section id="gallery" v-if="carouselImages.length !== 0" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
        <b-container>
            <VueSlickCarousel v-bind="settings">
                <div class="image-container" v-for="(image, index) in carouselImages" :key="index">
                    <img :src="image.url" alt="">
                </div>
            </VueSlickCarousel>
        </b-container>
    </section>
</template>
<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'CoworkingImageCarousel',
    components: {VueSlickCarousel},
    props: {
        carouselImages: {
            type: Array,
            required: true,
            validate: (val) => {
                return Array.isArray(val);
            }
        }
    },
    data() {
        return {
            settings: {
                dots: true,
                dotsClass: "slick-dots",
                edgeFriction: 0.15,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    }
}
</script>

<template>
    <section id="coworking" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
        <b-container>
            <SectionHeader :header-text="mainTitle"/>
            <div class="content">
                <div class="text-and-button-container">
                    <p class="bolder-medium-text">{{mainDescription}}</p>
                    <router-link :to="{name: 'coworking'}">
                        <CustomButton :buttonText="buttonText"/>
                    </router-link>
                </div>
                <img :src="imageUrl"/>
            </div>
        </b-container>
    </section>
</template>
<script>

import SectionHeader from "../global/SectionHeader/sectionHeader";
import CustomButton from "../global/CustomButton/customButton";
import {getCoworkingInfo} from "../../assets/js/services";
export default {
    name: 'Coworking',
    components: {CustomButton, SectionHeader},
    data() {
        return {
            mainTitle: '',
            mainDescription: '',
            buttonText: 'Check',
            imageUrl: ''
        }
    },
    async created(){
        const response = await getCoworkingInfo()
        const data = response.coworkingSections[0]

        this.mainTitle = data.mainTitle
        this.mainDescription = data.landingPageDescription
        this.imageUrl = data.landingPageImage.url
    }
}
</script>
<template>
    <section id="clients-and-partners" v-if="clientsAndPartners.length !== 0" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
        <b-container>
            <SectionHeader v-if="mainTitle" :header-text="mainTitle"/>
                <VueSlickCarousel v-bind="settings">
                    <div v-for="(partner, index) in clientsAndPartners" class="client-and-partner-container" :key="index">
                        <div class="main-content-container">
                            <div class="main-description">
                                <p>{{partner.description}}</p>
                            </div>
                            <div class="partner-container">
                                <div class="partner-section">
                                    <div>
                                        <img v-if="partner.firstPartnerImage" :src="partner.firstPartnerImage.url" alt="">
                                        <p>{{partner.firstPartnerName}}</p>
                                        <p class="partner-position__partner-company"><span>{{partner.firstPartnerPosition}}</span>{{partner.firstPartnerCompanyName}}</p>
                                        <a target="_blank" :href="partner.firstPartnerLink">{{partner.firstPartnerLinkText}}</a>
                                    </div>
                                </div>
                                <div class="partner-section">
                                    <div>
                                        <img v-if="partner.secondPartnerImage" :src="partner.secondPartnerImage.url" alt="">
                                        <p v-if="partner.secondPartnerName">{{partner.secondPartnerName}}</p>
                                        <p v-if="partner.secondPartnerCompanyName" class="partner-position__partner-company"><span>{{partner.secondPartnerPosition}}</span>{{partner.secondPartnerCompanyName}}</p>
                                        <a v-if="partner.secondPartnerLink" target="_blank" :href="partner.secondPartnerLink">{{partner.secondPartnerLinkText}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="main-image-container">
                            <img v-if="partner.mainImage" :src="partner.mainImage.url"/>
                        </div>
                    </div>
                </VueSlickCarousel>
        </b-container>
        <CompanyImage :company-images="companyImages"/>
    </section>
</template>
<script>

import SectionHeader from "../global/SectionHeader/sectionHeader";
import {getClientsAndPartners} from "../../assets/js/services";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import CompanyImage from "../CompanyImages/companyImages";

export default {
    name: 'ClientsAndPartners',
    components: { CompanyImage, SectionHeader, VueSlickCarousel},
    data() {
        return {
            mainTitle: '',
            clientsAndPartners: [],
            settings: {
                dots: true,
                dotsClass: "slick-dots",
                edgeFriction: 0.15,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
            },
            companyImages: []
        }
    },
    async created(){
        const response = await getClientsAndPartners()
        const data = response.clientAndPartners
        this.mainTitle = data[0].mainTitle
        this.clientsAndPartners = data
        this.companyImages = response.companyImages
    }
}
</script>

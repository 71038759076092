<template>
    <section id="what-we-do" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
        <b-container>
            <SectionHeader :header-text="mainTitle"/>
            <div class="text-container">
                <p class="bolder-medium-text">{{ rightSideText }}</p>
                <p class="small-text text-right" v-html="leftSideText"></p>
            </div>
            <hr/>
            <InformativeSectionContainer v-if="informationSectionMetaData !== []" :information-section-meta-data="informationSectionMetaData"/>
        </b-container>
    </section>
</template>
<script>

import SectionHeader from "../global/SectionHeader/sectionHeader";
import InformativeSectionContainer from "../global/InformativeSectionContainer/informativeSectionContainer";
import {getDescriptionAndInfoData} from "../../assets/js/services";

export default {
    name: 'WhatWeDo',
    components: {InformativeSectionContainer, SectionHeader},
    data() {
        return {
            mainTitle: '',
            rightSideText: '',
            leftSideText: '',
            informationSectionMetaData: []
        }
    },
    async created(){
        const response = await getDescriptionAndInfoData()

        const descriptionAndInfoData = response.informativeSectionWithTexts[0]

        this.mainTitle = descriptionAndInfoData.mainTitle
        this.rightSideText = descriptionAndInfoData.rightSideText
        this.leftSideText = descriptionAndInfoData.leftSideText.replace(/\n/g, "<br/>")
        this.informationSectionMetaData = descriptionAndInfoData.informativeSectionBlocks
    }
}
</script>
<template>
    <section id="company-images">
        <div class="blurred-shadow"></div>
        <div class="custom-image-container">
            <a target="_blank" v-for="(image, index) in companyImages" :href="image.companyImageLink" :key="index">
                <img :src="image.companyImage.url"/>
            </a>
        </div>
    </section>
</template>
<script>

export default {
    name: 'CompanyImage',
    props:{
        companyImages: {
            type: Array,
            required: true,
            validate: (val) => {
                return Array.isArray(val);
            }
        }
    },
    mounted() {
      const slider = document.querySelector('.custom-image-container');
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener('mousedown', (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mousemove', (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    },
  destroyed() {
    const slider = document.querySelector('.custom-image-container');
    slider.removeEventListener('mousedown')
    slider.removeEventListener('mouseleave')
    slider.removeEventListener('mouseup')
    slider.removeEventListener('mousemove')
  }
}
</script>

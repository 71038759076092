<template>
    <section class="informative-section">
        <p class="title bolder-medium-text">{{title}}</p>
        <p class="content">{{content}}</p>
        <p class="index">{{`0${index}`}}<span class="informative-section-length">{{`/0${dataLength}`}}</span></p>
    </section>
</template>
<script>

export default {
    name: 'InformativeSection',
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        dataLength: {
            type: Number,
            default: 0
        }
    }
}
</script>
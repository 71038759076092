<template>
    <div class="email-thank-you-page">
        <div class="header">
            <img :src="require('../../assets/img/icon.png')" alt="">
            <p class="title">INCUB&CO</p>
            <p class="small-text">jump startup</p>
        </div>
        <b-container class="small-text canceled-booking d-flex flex-column align-items-center justify-content-center">
            <p class="bolder-medium-text text-center mt-5">Oppss</p>
            <p class="bolder-medium-text text-center">The payment has been canceled!!!</p>
            <p class="small-text text-center mt-5">Feel free to try again to book?</p>
            <CustomButton button-text="Go to bookings" @onClickFunction="onClickFunction" class="m-auto"/>
        </b-container>
        <div class="footer">
            <div class="image-container">
                <img :src="require('../../assets/img/Twitter.png')" alt="">
                <img :src="require('../../assets/img/Facebook.png')" alt="">
                <img :src="require('../../assets/img/Instagram.png')" alt="">
            </div>
            <p>incub&co.com</p>
            <p>Route de la Maladière 21,<br>
                1022 Chavannes-près-Renens</p>
        </div>
    </div>
</template>

<script>
import CustomButton from "../global/CustomButton/customButton";

export default {
    name: 'CanceledPayment',
    components: {CustomButton},
    data() {
        return {
            bookingsWithBookingId: []
        }
    },
    methods: {
        onClickFunction() {
            this.$router.push({path: '/coworking#booking-section'})
        }
    }
}

</script>
<template>
    <footer id="footer">
        <b-container>
            <SectionHeader v-if="hasTitle" :header-text="mainTitle"/>
            <div class="footer-content">
                <ValidationObserver @submit.prevent v-slot="{ handleSubmit }" ref="observer" >
                    <div class="form-container">
                        <p class="bolder-medium-text">{{ formTitle }}</p>
                        <div class="input-container">
                            <ValidationProvider rules="required" v-slot="{ errors }" name="Name">
                                <input :class="errors.length !== 0? 'invalidInput':''" type="text" v-model="name" placeholder="NAME" pattern="^[a-zA-Z]+$"/>
                                <ul>
                                    <li v-for="(error, index) in errors" :key="index">{{error}}</li>
                                </ul>
                            </ValidationProvider>
                        </div>
                        <div class="input-container">
                            <div class="prefixSelect">
                                <select name="countryPrefix" id="countryPrefix" v-model="countryPrefix">
                                    <option v-for="(prefix, index) in countryPrefixes" :key="index" :value="prefix.dial_code">{{`${prefix.code}(${prefix.dial_code})`}}</option>
                                </select>
                            </div>
                            <ValidationProvider rules="required|integer" v-slot="{ errors }" name="Phone number">
                                <input :class="errors.length !== 0? 'invalidInput':''" type="tel" v-model="phoneNumber" placeholder="PHONE NUMBER" pattern="^[0-9]+$"/>
                                <ul>
                                    <li v-for="(error, index) in errors" :key="index">{{error}}</li>
                                </ul>
                            </ValidationProvider>
                        </div>
                        <div class="email-input">
                            <ValidationProvider rules="required|email" v-slot="{ errors }" name="Email">
                                <input :class="errors.length !== 0? 'invalidInput':''" type="email" v-model="email" placeholder="EMAIL"/>
                                <ul>
                                    <li v-for="(error, index) in errors" :key="index">{{error}}</li>
                                </ul>
                            </ValidationProvider>
                        </div>
                        <div class="privacy-policy-text">
                            <p>By clicking submit, you agree to incub&co’s
                                <router-link :to="{name: 'privacyPolicy'}">Privacy Policy</router-link>
                                and
                                <router-link :to="{name: 'termsAndConditions'}">Terms & Conditions</router-link>
                            </p>
                        </div>
                        <div @click="handleSubmit(onClickFunction())">
                            <CustomButton button-text="Submit" dark-theme/>
                        </div>
                    </div>
                </ValidationObserver>
                <div class="company-info-container">
                    <div class="address-container">
                        <p class="bolder-medium-text">Address</p>
                        <div class="addresses-container">
                            <div class="addresses" v-for="(address, index) in addresses" :key="index">
                                <p class="fw-bold">{{ address.title }} </p>
                                <p class="m-0">{{ address.streetName }}</p>
                                <p class="m-0">{{ address.zipAndCountry }}</p>
                                <a :href="`mailto:${address.email}`" class="m-0">{{ address.email }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="social-media-section">
                        <p class="bolder-medium-text">Social</p>
                        <div class="social-media-wrapper">
                            <a target="_blank" v-for="(link, index) in socialMediaLinks" :key="index"
                               :href="link.linkUrl">{{ link.linkText }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
        <b-container class="copy-right-container">
            <p class="copy-right-text">© Copyright 2021 incub&co. All rights reserved.</p>
        </b-container>
        <div v-if="showThanksModal" class="thanks-modal">
            <b-container>
                <p class="bolder-medium-text">Thank You!</p>
                <p class="small-text">Your request was successfully submitted.</p>
                <p class="small-text">Our team will contact you in the next 2 hours.</p>
                <p class="small-text" @click="hideThanksModal">back to the website</p>
            </b-container>
        </div>
    </footer>
</template>
<script>

import SectionHeader from "../global/SectionHeader/sectionHeader";
import CustomButton from "../global/CustomButton/customButton";
import {getFooterContent} from "../../assets/js/services";
import {$axios} from "../../assets/js/axiosInstance";
import countryPrefixes from '../../assets/js/countryPrefixes'

export default {
    name: 'Footer',
    components: {
        CustomButton,
        SectionHeader
    },
    props: {
        hasTitle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mainTitle: '',
            formTitle: '',
            addresses: [],
            socialMediaLinks: [],
            name: '',
            phoneNumber: '',
            email: '',
            showThanksModal: false,
            countryPrefixes,
            countryPrefix: countryPrefixes.find(this.selectSwitzerland).dial_code
        }
    },
    async created() {
        const response = await getFooterContent()
        const data = response.footers[0]
        this.mainTitle = data.mainTitle
        this.formTitle = data.formTitle
        this.addresses = [
            {
                streetName: data.firstAddressStreetName,
                zipAndCountry: data.firstAddressZipAndCountry,
                email: data.firstAddressEmail,
                title: data.firstAddressTitle
            },
            {
                streetName: data.secondAddressStreetName,
                zipAndCountry: data.secondAddressZipAndCountry,
                email: data.secondAddressEmail,
                title: data.secondAddressTitle
            }
        ]
        this.socialMediaLinks = [
            {
                linkText: data.firstSocialMediaText,
                linkUrl: data.firstSocialMediaLink
            },
            {
                linkText: data.secondSocialMediaText,
                linkUrl: data.secondSocialMediaLink
            },
            {
                linkText: data.thirdSocialMediaText,
                linkUrl: data.thirdSocialMediaLink
            },
        ]
    },
    methods: {
        async onClickFunction() {
            let payload = {
                "name": this.name,
                "email": this.email,
                "phone": this.countryPrefix + this.phoneNumber
            }
            const isValid = await this.$refs.observer.validate()

            if(isValid){
                $axios.post('/contact', payload).then((response) => {
                    if (response.data.message === 'success') {
                        this.showThanksModal = true
                    } else {
                        this.showThanksModal = true
                    }
                })
            }
        },
        selectSwitzerland(country){
            return country.name === "Switzerland"
        },
        hideThanksModal() {
            this.showThanksModal = false
        }
    }
}
</script>
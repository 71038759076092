<template>
    <div id="booking-section" v-if="bookingContentContainers.length !== 0"  data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
        <b-container>
            <div class="navigation">
                <div class="navigation-link" v-if="bookingContentContainers[0]" @click="setSelectedNav(1)" :class="selectedMenuId === 1 ? 'active' : '' ">
                    <p>{{ bookingContentContainers[0].navigationTitle }}</p>
                </div>
                <div class="navigation-link" v-if="bookingContentContainers[1]" @click="setSelectedNav(2)" :class="selectedMenuId === 2 ? 'active' : '' ">
                    <p>{{ bookingContentContainers[1].navigationTitle }}</p>
                </div>
                <div class="navigation-link" v-if="bookingContentContainers[2]" @click="setSelectedNav(3)" :class="selectedMenuId === 3 ? 'active' : '' ">
                    <p>{{ bookingContentContainers[2].navigationTitle }}</p>
                </div>
                <div class="navigation-link" v-if="bookingContentContainers[3]" @click="setSelectedNav(4)" :class="selectedMenuId === 4 ? 'active' : '' ">
                    <p>{{ bookingContentContainers[3].navigationTitle }}</p>
                </div>
            </div>
            <SelectedMenuContent
                v-if="selectedMenuId === 1"
                :has-bookings="true"
                :main-title="bookingContentContainers[0].mainTitle"
                :sub-title="bookingContentContainers[0].subTitle"
                :description-boxes="bookingContentContainers[0].descriptionBoxes"
                :main-image-url="bookingContentContainers[0].mainImage.url"
                :apiTitle="'SharedSpace'"
            />
            <SelectedMenuContent
                v-if="selectedMenuId === 2"
                :main-title="bookingContentContainers[1].mainTitle"
                :sub-title="bookingContentContainers[1].subTitle"
                :description-boxes="bookingContentContainers[1].descriptionBoxes"
                :main-image-url="bookingContentContainers[1].mainImage.url"
                :apiTitle="'DedicatedDesk'"
            />
            <SelectedMenuContent
                v-if="selectedMenuId === 3"
                :main-title="bookingContentContainers[2].mainTitle"
                :sub-title="bookingContentContainers[2].subTitle"
                :description-boxes="bookingContentContainers[2].descriptionBoxes"
                :main-image-url="bookingContentContainers[2].mainImage.url"
                :apiTitle="'ClosedOffices'"
            />
            <SelectedMenuContent
                v-if="selectedMenuId === 4"
                :has-bookings="true"
                :main-title="bookingContentContainers[3].mainTitle"
                :sub-title="bookingContentContainers[3].subTitle"
                :description-boxes="bookingContentContainers[3].descriptionBoxes"
                :main-image-url="bookingContentContainers[3].mainImage.url"
                :apiTitle="'MeetingRooms'"
            />
        </b-container>
    </div>
</template>

<script>

import SelectedMenuContent from "./selectedMenuContent";
import {getBookingSectionContent} from "../../assets/js/services";
export default {
    name: 'BookingSection',
    components: {SelectedMenuContent},
    data() {
        return {
            selectedMenuId: 1,
            bookingContentContainers: []
        }
    },
    async created() {
        const response = await getBookingSectionContent()
        const data = response.bookingSections[0]
        this.bookingContentContainers = data.bookingContentContainers
    },
    methods: {
        setSelectedNav(navId) {
            this.selectedMenuId = navId
        }
    }
}

</script>
const GET_FIRST_SECTION_CONTENT = `query getFirstSectionContent {
  landingPageHeroSections {
    rightSideText
    leftSideText
    heroSectionImage {
      url
    }
  }
}
`

const GET_ABOUT_PAGE_WITH_THREE_IMG_CONTENT = `query getAboutPageWithThreeImgContent {
  aboutPageWithThreeImgs {
    mainTitle
    leftSideText
    rightSideText
    sectionImage {
      url
    }
  }
}
`

const GET_DESCRIPTION_AND_INFO_DATA = `query getDescriptionAndInfoData {
  informativeSectionWithTexts {
    mainTitle
    rightSideText
    leftSideText
    informativeSectionBlocks {
      title
      content
    }
  }
}
`

const GET_INFORMATIVE_SECTION_WITH_TITLE = `query getInformativeSectionWithTitle {
  informativeSectionWithoutTexts {
    mainTitle
    darkSectionMainTitle
    darkSectionDescription
    darkSectionLinkText
    darkSectionLink
    darkSectionImage{
        url
    }
    informativeSectionBlocks {
      title
      content
    }
  }
}
`

const GET_CLIENTS_AND_PARTNERS = `query getClientsAndPartners {
  clientAndPartners {
    mainTitle
    mainImage {
      url
    }
    description
    firstPartnerImage{
      url
    }
    firstPartnerName
    firstPartnerCompanyName
    firstPartnerPosition
    firstPartnerLinkText
    firstPartnerLink
    secondPartnerImage{
      url
    }
    secondPartnerName
    secondPartnerCompanyName
    secondPartnerPosition
    secondPartnerLinkText
    secondPartnerLink
  }
companyImages {
  companyImage {
    url
  }
  companyImageLink
}
}
`

const GET_COWORKING_INFO = `query getCoworkingInfo {
  coworkingSections {
    mainTitle
    landingPageDescription
    landingPageImage {
      url
    }
    coworkingPageDescription
    coworkingPageImage {
      url
    }
  }
}
`

const GET_CONTENT_WITH_NAVIGATION = `query getContentWithNavigation {
  contentWithNavigations {
    mainTitle
    description
    contentContainers {
      contentTitle
      contentBoxes {
        title
        description
      }
    }
  }
}
`

const GET_FOOTER_CONTENT = `query getFooterContent {
  footers {
    mainTitle
    formTitle
    firstAddressTitle
    firstAddressStreetName
    firstAddressZipAndCountry
    firstAddressEmail
    firstSocialMediaLink
    firstSocialMediaText
    secondAddressTitle
    secondAddressStreetName
    secondAddressZipAndCountry
    secondAddressEmail
    secondSocialMediaLink
    secondSocialMediaText
    thirdSocialMediaText
    thirdSocialMediaLink
  }
}
`

const GET_PRIVACY_POLICY_CONTENT = `query getPrivacyPolicyContent {
  privacyPolicies {
    title
    introText
    contentSections {
      title
      content {
        html
      }
    }
    updatedAt
  }
}
`


const GET_TERMS_AND_CONDITIONS = `query getTermsAndConditions {
  termAndConditions {
    title
    contentSections {
      title
      content {
        html
      }
    }
    updatedAt
  }
}
`

const GET_PAGE_TITlE_AND_ICON = `query getPageTitleAndIcon {
  pageTitles {
    title
    icon {
      url
    }
  }
}
`

const GET_COWORKING_HERO_SECTION_CONTENT = `query getCoworkingHeroSectionContent {
  coworkingHeroSections{
    mainTitle
    text{
      html
    }
    mainImage{
      url
    }
  }
}
`

const GET_BENEFIT_SECTION_CONTENT = `query getBenefitSectionContent {
  benefitSections {
    rightSideText
    leftSideText
    benefitBoxes {
      mainTitle
      description
      icon {
        url
      }
    }
    carouselImage {
      url
    }
  }
}
`

const GET_BOOKING_SECTION_CONTENT = `query getBookingSectionContent {
    bookingSections {
        bookingContentContainers {
          mainImage {
            url
          }
          navigationTitle
          mainTitle
          subTitle
          descriptionBoxes {
            title
            image {
              url
            }
          }
       }
    }
}`

export {
    GET_FIRST_SECTION_CONTENT,
    GET_ABOUT_PAGE_WITH_THREE_IMG_CONTENT,
    GET_DESCRIPTION_AND_INFO_DATA,
    GET_INFORMATIVE_SECTION_WITH_TITLE,
    GET_CLIENTS_AND_PARTNERS,
    GET_COWORKING_INFO,
    GET_CONTENT_WITH_NAVIGATION,
    GET_FOOTER_CONTENT,
    GET_PRIVACY_POLICY_CONTENT,
    GET_TERMS_AND_CONDITIONS,
    GET_PAGE_TITlE_AND_ICON,
    GET_COWORKING_HERO_SECTION_CONTENT,
    GET_BENEFIT_SECTION_CONTENT,
    GET_BOOKING_SECTION_CONTENT
};

<template>
    <section id="first-screen">
        <b-container class="text-container">
            <p class="bolder-medium-text">{{ rightSideText }}</p>
            <p class="small-text" v-html="leftSideText"></p>
        </b-container>
        <div class="custom-container">
            <img :src="firstScreenImageSrc" alt=""/>
        </div>
    </section>
</template>
<script>
import {getFirstSectionContent} from "../../assets/js/services";

export default {
    name: 'FirstScreen',
    data() {
        return {
            firstScreenImageSrc: '',
            rightSideText: '',
            leftSideText: ''
        }
    },
    async created() {
        const response = await getFirstSectionContent()

        const data = response.landingPageHeroSections[0]
        this.firstScreenImageSrc = data.heroSectionImage.url
        this.rightSideText = data.rightSideText
        this.leftSideText = data.leftSideText.replace(/\n/g, "<br/>")
    }
}
</script>
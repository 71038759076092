<template>
    <div>
        <Navbar :nav-links="navLinks" button-text="Contact us" button-reference="contactUs" />
        <div id="landing-page">
            <FirstScreen/>
            <WhoWeAre/>
            <WhatWeDo/>
            <WeWorkWith/>
            <ClientsAndPartners/>
            <Offers/>
            <Coworking/>
            <div id="contactUs">
                <Footer hasTitle/>
            </div>
        </div>
    </div>
</template>

<script>

import FirstScreen from "../../components/FirstSection/firstSection";
import WhoWeAre from "../../components/WhoWeAre/whoWeAre";
import WhatWeDo from "../../components/WhatWeDo/whatWeDo";
import WeWorkWith from "../../components/WeWorkWith/weWorkWith";
import ClientsAndPartners from '../../components/ClientsAndPartners/clientsAndPartners'
import Offers from "../../components/Offers/offers";
import Coworking from "../../components/Coworking/coworking";
import Footer from "../../components/Footer/footer";
import Navbar from "../../components/Navbar/navbar"

export default {
    name: 'LandingPage',
    components: {
        Footer,
        Coworking,
        WeWorkWith,
        WhatWeDo,
        WhoWeAre,
        FirstScreen,
        ClientsAndPartners,
        Offers,
        Navbar
    },
    data(){
        return{
            navLinks: [
                {
                    text: 'Who are we',
                    url: '/#who-we-are'
                },
                {
                    text: 'What we do',
                    url: '/#what-we-do'
                },
                {
                    text: 'We work with',
                    url: '/#we-work-with'
                },
                {
                    text: 'Clients',
                    url: '/#clients-and-partners'
                },
                {
                    text: 'Offers',
                    url: '/#offers'
                },
                {
                    text: 'Coworking',
                    url: '/coworking'
                }
            ]
        }
    }
}

</script>
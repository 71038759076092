<template>
    <div class="coworking-page-wrapper">
        <Navbar :nav-links="navLinks" :class="navToggled ? 'coworking':''" button-text="Book"
                button-reference="booking-section"/>
        <div id="coworking-page">
            <CoworkingHeroSection/>
            <CoworkingBenefits/>
            <BookingSection/>
            <CoworkingEndingSection/>
            <div id="contactUs">
                <Footer/>
            </div>
        </div>
    </div>
</template>

<script>

import Navbar from "../../components/Navbar/navbar";
import CoworkingHeroSection from "../../components/CoworkingHeroSection/coworkingHeroSection";
import CoworkingBenefits from "../../components/CoworkingBenefits/coworkingBenefits";
import Footer from "../../components/Footer/footer";
import CoworkingEndingSection from "../../components/CoworkingEndingSection/coworkingEndingSection";
import BookingSection from "../../components/BookingSection/bookingSection";

export default {
    name: 'Coworking',
    components: {
        BookingSection,
        CoworkingEndingSection,
        Footer,
        CoworkingBenefits,
        CoworkingHeroSection,
        Navbar
    },
    data() {
        return {
            navLinks: [
                {
                    text: 'About',
                    url: '#about'
                },
                {
                    text: 'Gallery',
                    url: '#gallery'
                },
                {
                    text: 'Offers',
                    url: '#booking-section'
                },
                {
                    text: 'incub&co',
                    url: '/'
                },
                {
                    text: 'Contact',
                    url: '#contactUs'
                }
            ],
            navToggled: true
        }
    },
    mounted() {
        this.checkScreenWidth()
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            let currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            this.navToggled = currentScrollPosition > 750 ? false : true
        },
        checkScreenWidth() {
            if(window.innerWidth <= 1025){
                this.navToggled = false
            }else{
                window.addEventListener('scroll', this.onScroll)
                this.navToggled = true
            }
        }
    }
}

</script>
<template>
    <div id="default-layout" class="custom-container">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'Default'
}
</script>

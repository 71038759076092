import VueRouter from 'vue-router'

const routes = [
    {
        path: '',
        component: require('../../../layouts/default.vue').default,
        children: [
            {
                path: '/',
                component: require('../../../pages/landing-page/landingPage.vue').default,
                name: 'landingPage'
            },
            {
                path: '/privacy-policy',
                component: require('../../../components/PrivacyAndPolicy/PrivacyAndPolicy').default,
                name: 'privacyPolicy'
            },
            {
                path: '/terms-and-conditions',
                component: require('../../../components/TermsAndConditions/termsAndConditions').default,
                name: 'termsAndConditions'
            },
            {
                path: '/coworking',
                component: require('../../../pages/coworking-page/coworking-page').default,
                name: 'coworking'
            },
            {
                path: '/success',
                component: require('../../../components/EmailThankYouPage/coworkingEmail').default,
                name: 'SuccessfulPayment'
            },
            {
                path: '/cancel',
                component: require('../../../components/EmailThankYouPage/canceledPayment').default,
                name: 'CanceledPayment'
            }
        ]
    }
]
const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    document.documentElement.style.scrollBehavior = 'unset';
    window.scrollTo(0, 0)
    document.documentElement.style.scrollBehavior = 'auto';
    next()
})

export default router
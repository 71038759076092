<template>
    <div id="navbar">
        <b-container class="d-flex justify-content-between align-items-center position-relative">
            <div class="menu-toggle-container">
                <div class="burgerMenu" :class="isNavOpened? 'opened':'closed'" @click="toggleNavBar">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <p class="small-text" v-if="!isNavOpened">Menu</p>
            </div>
            <p class="title m-0"><router-link :to="{name: 'landingPage'}">INCUB&CO</router-link></p>
            <NavbarMenu :nav-links="navLinks" :class="isNavOpened? 'opened':'closed'"/>
            <b-nav-item :href="`#${buttonReference}`" class="navbar-button-container">
                <CustomButton
                    :button-text="buttonText"
                />
            </b-nav-item>
        </b-container>
    </div>
</template>

<script>

import NavbarMenu from "./NavbarMenu/navbarMenu";
import CustomButton from "../global/CustomButton/customButton";
export default {
    name: 'Navbar',
    components: {CustomButton, NavbarMenu},
    props: {
        navLinks: {
            type: Array,
            required: true,
            validate: (val) => {
                return Array.isArray(val);
            }
        },
        buttonText: String,
        buttonReference: String
    },
    data() {
        return {
            isNavOpened: false
        }
    },
    methods:{
        toggleNavBar(){
            this.isNavOpened = !this.isNavOpened
        }
    }
}
</script>
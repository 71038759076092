<template>
    <div class="custom-navigation-content">
        <div class="content-box">
            <p v-for="(keyName, index) in Object.keys(contentBoxes[0])" :key="index">{{contentBoxes[0][keyName]}}</p>
            <p>01<span>/04</span></p>
        </div>
        <div class="content-box empty">
        </div>
        <div class="content-box">
            <p v-for="(keyName, index) in Object.keys(contentBoxes[1])" :key="index">{{contentBoxes[1][keyName]}}</p>
            <p>02<span>/04</span></p>
        </div>
        <div class="content-box empty">
        </div>
        <div class="content-box empty">
        </div>
        <div class="content-box">
            <p v-for="(keyName, index) in Object.keys(contentBoxes[2])" :key="index">{{contentBoxes[2][keyName]}}</p>
            <p>03<span>/04</span></p>
        </div>
        <div class="content-box empty">
        </div>
        <div class="content-box">
            <p v-for="(keyName, index) in Object.keys(contentBoxes[3])" :key="index">{{contentBoxes[3][keyName]}}</p>
            <p>04<span>/04</span></p>
        </div>
    </div>
</template>
<script>

export default {
    name: 'SecondContentStyle',
    props: {
        contentBoxes: {
            type: Array,
            required: true,
            validate: (val) => {
                return Array.isArray(val);
            }
        }
    }
}
</script>
<template>
    <section id="we-work-with" data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
        <b-container>
            <SectionHeader :header-text="mainTitle"/>
        </b-container>
        <div class="dark-section">
            <b-container>
                <div class="content">
                    <div class="text-and-button-container">
                        <p class="bolder-medium-text">{{darkSectionMainTitle}}</p>
                        <p class="small-text">{{darkSectionDescription}}</p>
                        <a :href="darkSectionLinkUrl" target="_blank">
                            {{darkSectionLinkText}}
                        </a>
                    </div>
                    <img :src="darkSectionImageUrl"/>
                </div>
            </b-container>
        </div>
        <b-container>
            <InformativeSectionContainer v-if="informationSectionMetaData !== []" :information-section-meta-data="informationSectionMetaData"/>
        </b-container>
    </section>
</template>
<script>

import SectionHeader from "../global/SectionHeader/sectionHeader";
import InformativeSectionContainer from "../global/InformativeSectionContainer/informativeSectionContainer";
import {getInformativeSectionWithTitle} from "../../assets/js/services";

export default {
    name: 'WeWorkWith',
    components: {InformativeSectionContainer, SectionHeader},
    data() {
        return {
            mainTitle: '',
            informationSectionMetaData: [],
            darkSectionMainTitle: '',
            darkSectionDescription: '',
            darkSectionImageUrl: '',
            darkSectionLinkText: '',
            darkSectionLinkUrl: ''
        }
    },
    async created(){
        const response = await getInformativeSectionWithTitle()

        const data = response.informativeSectionWithoutTexts[0]

        this.mainTitle = data.mainTitle
        this.informationSectionMetaData = data.informativeSectionBlocks
        this.darkSectionMainTitle = data.darkSectionMainTitle
        this.darkSectionDescription = data.darkSectionDescription
        this.darkSectionImageUrl = data.darkSectionImage.url
        this.darkSectionLinkText = data.darkSectionLinkText
        this.darkSectionLinkUrl = data.darkSectionLink
    }
}
</script>
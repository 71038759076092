import {gql} from 'graphql-request';
import {
    GET_ABOUT_PAGE_WITH_THREE_IMG_CONTENT,
    GET_CLIENTS_AND_PARTNERS,
    GET_DESCRIPTION_AND_INFO_DATA,
    GET_FIRST_SECTION_CONTENT,
    GET_INFORMATIVE_SECTION_WITH_TITLE,
    GET_COWORKING_INFO,
    GET_CONTENT_WITH_NAVIGATION,
    GET_FOOTER_CONTENT,
    GET_PRIVACY_POLICY_CONTENT,
    GET_TERMS_AND_CONDITIONS,
    GET_PAGE_TITlE_AND_ICON,
    GET_COWORKING_HERO_SECTION_CONTENT,
    GET_BENEFIT_SECTION_CONTENT,
    GET_BOOKING_SECTION_CONTENT
} from '../fragments'
import {graphCmsClient} from "../../../main";

export function getFirstSectionContent() {
    return graphCmsClient.request(
        gql`${GET_FIRST_SECTION_CONTENT}`
    );
}

export function getAboutPageWithThreeImgContent() {
    return graphCmsClient.request(
        gql`${GET_ABOUT_PAGE_WITH_THREE_IMG_CONTENT}`
    );
}

export function getDescriptionAndInfoData() {
    return graphCmsClient.request(
        gql`${GET_DESCRIPTION_AND_INFO_DATA}`
    );
}

export function getInformativeSectionWithTitle() {
    return graphCmsClient.request(
        gql`${GET_INFORMATIVE_SECTION_WITH_TITLE}`
    );
}

export function getClientsAndPartners() {
    return graphCmsClient.request(
        gql`${GET_CLIENTS_AND_PARTNERS}`
    );
}

export function getCoworkingInfo() {
    return graphCmsClient.request(
        gql`${GET_COWORKING_INFO}`
    );
}


export function getContentWithNavigation() {
    return graphCmsClient.request(
        gql`${GET_CONTENT_WITH_NAVIGATION}`
    );
}

export function getFooterContent() {
    return graphCmsClient.request(
        gql`${GET_FOOTER_CONTENT}`
    );
}

export function getPrivacyPolicyContent() {
    return graphCmsClient.request(
        gql`${GET_PRIVACY_POLICY_CONTENT}`
    );
}

export function getTermsAndConditions() {
    return graphCmsClient.request(
        gql`${GET_TERMS_AND_CONDITIONS}`
    );
}


export function getPageTitleAndIcon() {
    return graphCmsClient.request(
        gql`${GET_PAGE_TITlE_AND_ICON}`
    );
}

export function getCoworkingHeroSectionContent() {
    return graphCmsClient.request(
        gql`${GET_COWORKING_HERO_SECTION_CONTENT}`
    );
}

export function getBenefitSectionContent() {
    return graphCmsClient.request(
        gql`${GET_BENEFIT_SECTION_CONTENT}`
    );
}

export function getBookingSectionContent() {
    return graphCmsClient.request(
        gql`${GET_BOOKING_SECTION_CONTENT}`
    );
}
<template>
    <div class="selected-menu-content">
        <div class="booking-section-content"
             :class="apiTitle !== 'SharedSpace' && apiTitle !== 'MeetingRooms' ?'pb-0': ''">
            <img class="mainImage" :src="mainImageUrl" alt="">
            <div class="summary-content">
                <p class="bolder-medium-text">{{ mainTitle }}</p>
                <p class="small-text">{{ subTitle }}</p>
                <div class="desc-img-container">
                    <div class="desc-img-box" v-for="(dscBox, index) in descriptionBoxes" :key="index">
                        <img :src="dscBox.image.url" alt="">
                        <p>{{ dscBox.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bookings-wrapper" v-if="hasBookings">
            <div class="picker-container">
                <div class="date-picker-container" v-if="apiTitle === 'MeetingRooms'">
                    <Datepicker
                        class="date-picker"
                        v-model="meetingRoomDate"
                        format="MMMM, dd"
                        :disabledDates="disabledDates"
                    ></Datepicker>
                    <div @click="bookAll" v-if="meetingRoomTimes.length !== 0 && selectedDay !== 6 && selectedDay !== 0" class="meeting-rooms-button-container">
                        <CustomButton button-text="Book all"/>
                    </div>
                </div>
                <div v-else class="d-flex justify-content-start align-items-center">
                    <vue-monthly-picker
                        v-model="selectedMonth" :monthLabels="monthLabels" dateFormat="MMMM"
                        :max="maximumDateSharedSpace">
                    </vue-monthly-picker>
                </div>
                <p :class="`m-0 notifier ${apiTitle === 'SharedSpace' ? 'shared-space' : 'meeting-room'}`">{{
                        apiTitle === 'SharedSpace' ? '' : '- Select the date you want to book!'
                    }}</p>
            </div>
            <div class="bookings-container shared-space" v-if="bookings.length !== 0 && apiTitle === 'SharedSpace'">
                <span v-for="(booking, index) in bookings" :key="index">
                    <div class="booking" v-if="!giveProperClass(booking).includes('occupied')"
                         @click="toggleBooking(booking)"
                         :class="`${giveProperClass(booking)}`">
                        <p>{{ $moment(booking.fields.BookingDate).format('DD.MM') }}</p>
                    </div>
                </span>
            </div>
            <div class="minHeight" v-else>
                <div class="bookings-container meeting-rooms" v-if="selectedDay !== 6 && selectedDay !== 0">
                    <span v-for="(booking, index) in meetingRoomTimes" :key="index">
                        <div class="booking"
                             v-if="!giveProperClassMeetingRoomBooking(booking).includes('occupied')"
                             @click="toggleBookingMeetingRoom(booking, index)"
                             :class="`${giveProperClassMeetingRoomBooking(booking)} ${ checkToDisableBox(booking) }`">
                            <p>{{ booking.fields.Time.split(' - ')[0] }}</p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div v-if="showSelectedBookings">
            <div class="show-more-info-section" v-if="selectedBookings.length !== 0">
                <div class="date-container info-container">
                    <p class="bolder-medium-text">Date</p>
                    <p class="small-text" v-for="(selectedBooking, index) in selectedBookings" :key="index">
                        {{ $moment(selectedBooking.fields.BookingDate).format('MMMM, DD') }}</p>
                </div>
                <div class="desks-container info-container">
                    <p class="bolder-medium-text">{{ apiTitle === 'MeetingRooms' ? 'Time' : 'Desks' }}</p>
                    <span v-for="(selectedBooking, index) in selectedBookings" :key="index">
                        <div class="small-text" v-if="apiTitle === 'SharedSpace'">
                            <p :class="selectedBookingDesks[index] === 1 ? 'hide': ''"
                               @click="controlDeskNumber(false, index ,selectedBooking.fields.MaximumDesks - (selectedBooking.fields.DesksRequested? selectedBooking.fields.DesksRequested: 0) )">
                                -</p>
                            {{ selectedBookingDesks[index] }} / {{
                                selectedBooking.fields.MaximumDesks - (selectedBooking.fields.DesksRequested ? selectedBooking.fields.DesksRequested : 0)
                            }}
                            <p :class="selectedBookingDesks[index] === selectedBooking.fields.MaximumDesks - (selectedBooking.fields.DesksRequested? selectedBooking.fields.DesksRequested: 0)  ? 'hide': ''"
                               @click="controlDeskNumber(true, index, selectedBooking.fields.MaximumDesks - (selectedBooking.fields.DesksRequested? selectedBooking.fields.DesksRequested: 0) )">
                                +</p>
                        </div>
                        <div class="small-text" v-else>
                            <p>{{ selectedBookings[index].fields.Time }}</p>
                        </div>
                    </span>
                </div>
                <div class="price-container info-container">
                    <p class="bolder-medium-text">Price</p>
                    <span v-for="(selectedBooking, index) in selectedBookings" :key="index">
                        <p class="small-text w-100" v-if="apiTitle === 'SharedSpace'">{{
                                'CHF' + returnCalculatedDiscountPrice(index)
                            }}</p>
                        <p class="small-text w-100"
                           v-else>{{ "CHF " + selectedBookings[index].fields.Price + " per hour" }}</p>
                    </span>
                </div>
                <div class="discount-container info-container" v-if="apiTitle === 'SharedSpace'">
                    <p class="bolder-medium-text">Discount offers</p>
                    <span v-if="selectedBookings.length !== 0 && apiTitle === 'SharedSpace'">
                        <p class="small-text" v-for="(discountOffer, index) in discountOffers" :key="index">
                        {{ discountOffer.fields.DaysBooked }} Day(s) - CHF {{ discountOffer.fields.BasePrice }} per
                        day</p>
                    </span>
                </div>
            </div>
            <div class="total-container" v-if="selectedBookings.length !== 0">
                <p class="bolder-medium-text">Total price: <span>CHF {{ returnTotalPrice() }}</span></p>
            </div>
        </div>
        <p class="mt-5" v-if="apiTitle !== 'SharedSpace' && apiTitle !== 'MeetingRooms'">If you would like to make a
            reservation, please contact us using our<br>contact form</p>
        <div class="button-container" v-if="this.apiTitle === 'SharedSpace' || this.apiTitle === 'MeetingRooms'">
            <CustomButton v-if="this.selectedBookings.length !== 0"
                          :button-text="buttonText" :loading="loading" @onClickFunction="onClickFunction"/>
        </div>
        <div class="button-container" v-else>
            <a href="#contactUs">
                <CustomButton :button-text="'Contact'"/>
            </a>
        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker'
import VueMonthlyPicker from 'vue-monthly-picker'
import CustomButton from "../global/CustomButton/customButton";
import {$axios} from "../../assets/js/axiosInstance";

export default {
    name: 'SelectedMenuContent',
    components: {CustomButton, Datepicker, VueMonthlyPicker},
    props: {
        mainTitle: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: true
        },
        mainImageUrl: {
            type: String,
            required: true
        },
        descriptionBoxes: {
            type: Array,
            required: true,
            validate: (val) => {
                return Array.isArray(val);
            }
        },
        hasBookings: {
            type: Boolean,
            default: false
        },
        apiTitle: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            selectedDay: '',
            currentTime: new Date().getHours(),
            buttonText: 'Pay with Stripe',
            bookings: [],
            meetingRoomDate: new Date(),
            selectedBookings: [],
            selectedBookingDesks: [],
            showSelectedBookings: false,
            discountOffers: [],
            totalBookingPrices: [],
            totalPrice: 0,
            meetingRoomBookings: [],
            meetingRoomTimes: [],
            disabledDates: {
                ranges: []
            },
            maximumDateSharedSpace: new Date(new Date().setMonth(new Date().getMonth() + 6)),
            transactionId: '',
            stripeResponse: null,
            loading: false,
            selectedMonth: this.$moment(),
            unfilteredBookings: [],
            monthLabels: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ]
        }
    },
    watch: {
        apiTitle(value) {
            this.bookings = []
            this.selectedBookingDesks = []
            this.selectedBookings = []
            this.showSelectedBookings = false
            if(value === 'MeetingRooms'){
                this.meetingRoomDate = this.meetingRoomDate.setDate(this.meetingRoomDate.getDate() + 1)
            }else{
                this.meetingRoomDate = new Date()
            }
            this.getBookings(this.apiTitle)
            if (this.apiTitle === 'MeetingRooms') {
                this.setBookingTimes()
            }
            this.returnButtonText()
        },
        selectedBookings() {
            if (this.selectedBookingDesks.length !== this.selectedBookings.length) {
                let tempArray = [
                    ...this.selectedBookingDesks
                ]
                this.selectedBookings.forEach((booking, index) => {
                    tempArray[index] = this.selectedBookingDesks[index] ? this.selectedBookingDesks[index] : 1
                })
                this.selectedBookingDesks = tempArray
            }
            this.rerenderComponent()
        },
        meetingRoomDate(value) {
            this.selectedDay = new Date(value).getDay()
            this.setBookingTimes()
            this.getBookings(this.apiTitle)
        },
        selectedMonth() {
            this.filterBookingsByMonth(this.selectedMonth, this.unfilteredBookings)
        }
    },
    created() {
        this.getBookings(this.apiTitle)
        this.getDiscountOffers()
    },
    mounted() {
        this.fillDisabledDates()
    },
    methods: {
        getDiscountOffers() {
            $axios.get(`/discounts?sort[0][field]=DaysBooked&sort[0][direction]=desc`).then((response) => {
                this.discountOffers = response.data
            })
        },
        onClickFunction() {
            this.saveBookings(this.apiTitle)
        },
        returnButtonText() {
            if (this.hasBookings) {
                this.buttonText = "Pay with Stripe"
            } else {
                this.buttonText = "Contact"
            }
        },
        getBookings(apiTitle) {
            if (apiTitle === "SharedSpace") {
                $axios.get(`/shared-spaces?sort[0][field]=BookingDate&sort[0][direction]=asc`).then((response) => {
                    let tempArray = []
                    response.data.forEach((booking)=>{
                        if(booking.fields.BookingDate !== this.$moment().format('YYYY-MM-DD')){
                            tempArray.push(booking)
                        }
                    })
                    this.unfilteredBookings = tempArray
                    this.filterBookingsByMonth(this.selectedMonth, response.data)
                })
            } else if (apiTitle === "MeetingRooms") {
                $axios.get(`/meeting-rooms`).then((response) => {
                    let bookings = []
                    response.data.forEach((record) => {
                        bookings.push({
                            id: record.id,
                            fields: {
                                ...record.fields,
                                GeneratedId: `${record.fields.TimeFrom}${this.$moment(record.fields.BookingDate).format('MMDDYYYY')}`
                            }
                        })
                    })
                    this.bookings = bookings
                })
            }
        },
        saveBookings(apiTitle) {
            this.loading = true
            let bookingsNameSpace = apiTitle === 'SharedSpace' ? 'sharedSpaces' : 'meetingRooms'
            let jsonRecords = ``
            this.selectedBookings.forEach((booking, index) => {
                jsonRecords += `{
                    "${apiTitle === 'SharedSpace' ? "id" : "date"}": "${apiTitle === 'SharedSpace' ? booking.id : `${this.$moment(this.meetingRoomDate).format('YYYY-MM-DD')}`}",
                    "${apiTitle === 'SharedSpace' ? "desksRequested" : "timeFrom"}": ${apiTitle === 'SharedSpace' ? `${this.selectedBookingDesks[index]}` : `"${booking.fields.Time.split(' - ')[0]}"`}
                }${index + 1 !== this.selectedBookings.length ? ',' : ''}`
            })
            let payload = `{
                "sharedSpaces": [
                    ${bookingsNameSpace === 'sharedSpaces' ? jsonRecords : ''}
                ],
                "meetingRooms": [
                    ${bookingsNameSpace === 'meetingRooms' ? jsonRecords : ''}
                ]
            }`

            $axios.post(`/checkout`, payload).then((res) => {
                localStorage.setItem('bookings', JSON.stringify(this.selectedBookings))
                window.location.href = res.data.url
                this.loading = false
            })
        },
        toggleBooking(clickedBooking) {
            let tempIndex = null
            if (clickedBooking.fields.Occupied && clickedBooking.fields.Paid) {
                return
            }
            if (this.selectedBookings.length !== 0) {
                let foundSelectedBooking = {}
                this.selectedBookings.forEach((booking, index) => {
                    if (booking.id === clickedBooking.id) {
                        foundSelectedBooking = booking
                        tempIndex = index
                    }
                })
                if (foundSelectedBooking.id === clickedBooking.id) {
                    this.selectedBookings = this.arrayRemove(clickedBooking.id)
                    this.totalBookingPrices = this.totalBookingPrices.filter((price, index) => tempIndex === index)
                } else {
                    this.selectedBookings.push(clickedBooking)
                }
            } else {
                this.selectedBookings.push(clickedBooking)
            }
        },
        arrayRemove(value) {
            return this.selectedBookings.filter(function (ele) {
                return ele.id !== value;
            });
        },
        giveProperClass(booking) {
            let className = ''
            if (booking.fields.Occupied) {
                className = 'occupied'
            }
            this.selectedBookings.forEach((selectedBooking) => {
                if (selectedBooking.id === booking.id) {
                    className = className.concat(' selected')
                }
            })
            return className
        },
        controlDeskNumber(isIncrease, index, maximumDesks) {
            if (isIncrease) {
                if (this.selectedBookingDesks[index] < maximumDesks) {
                    this.selectedBookingDesks[index] += 1
                } else {
                    return
                }
            } else {
                if (this.selectedBookingDesks[index] !== 1) {
                    this.selectedBookingDesks[index] -= 1
                } else {
                    return
                }
            }
            this.rerenderComponent()
        },
        returnTotalPrice() {
            let totalAmount = 0
            if (this.apiTitle === 'SharedSpace') {
                if (this.totalBookingPrices) {
                    this.totalBookingPrices.forEach((price) => {
                        totalAmount += price
                    })
                }
                this.totalPrice = totalAmount
            } else {
                this.selectedBookings.forEach(() => {
                    totalAmount += 100
                })
            }
            return totalAmount
        },
        returnCalculatedDiscountPrice(index) {
            let alreadySet = false
            let discountedPrice = this.discountOffers.find((offer) => offer.fields.DaysBooked === 1).fields.BasePrice
            this.discountOffers.forEach((discountOffer) => {
                if ((this.selectedBookings.length !== 1 && discountOffer.fields.DaysBooked !== 1) && (this.selectedBookings.length >= discountOffer.fields.DaysBooked)) {
                    if (!alreadySet) {
                        discountedPrice = discountOffer.fields.BasePrice
                        alreadySet = true
                    }
                }
            })
            this.totalBookingPrices[index] = discountedPrice * this.selectedBookingDesks[index]
            return discountedPrice * this.selectedBookingDesks[index]
        },
        rerenderComponent() {
            this.showSelectedBookings = false
            this.$nextTick(() => {
                this.showSelectedBookings = true
            })
        },
        fillDisabledDates() {
            let tzoffset = new Date().getTimezoneOffset() * 60000;

            let today = (new Date(Date.now() - tzoffset));

            today.setDate(today.getDate() + 1);

            let oldToday = new Date(today.getTime());
            oldToday.setDate(oldToday.getDate() - 1);

            today.setMonth(today.getMonth() + 12);

            let obj = {};

            obj["from"] = new Date(0, 0, 0);
            obj["to"] = oldToday;

            this.disabledDates["ranges"].push(obj);
            this.disabledDates["from"] = today;
        },
        giveProperClassMeetingRoomBooking(booking) {
            let className = ''
            this.bookings.forEach((allBookings) => {
                if (allBookings.fields.GeneratedId === booking.fields.GeneratedId) {
                    if (allBookings.fields.Paid) {
                        className = className.concat(' occupied meetingRoom')
                    }
                }
            })
            this.selectedBookings.forEach((selectedBooking) => {
                if (selectedBooking.fields.GeneratedId === booking.fields.GeneratedId) {
                    className = className.concat(' selected')
                }
            })
            return className
        },
        toggleBookingMeetingRoom(clickedBooking) {
            const generatedId = `${clickedBooking.fields.Time.split(' - ')[0]}${this.$moment(this.meetingRoomDate).format('MMDDYYYY')}`;
            let bookingObject = {
                id: generatedId,
                fields: {
                    BookingDate: this.meetingRoomDate,
                    Time: clickedBooking.fields.Time,
                    Price: 100,
                    GeneratedId: generatedId
                }
            }
            if (this.selectedBookings.length !== 0) {
                let foundSelectedBooking = {}
                this.selectedBookings.forEach((booking) => {
                    if (booking.id === generatedId) {
                        foundSelectedBooking = booking
                    }
                })
                if (foundSelectedBooking.id === generatedId) {
                    this.selectedBookings = this.arrayRemove(generatedId)
                } else {
                    this.selectedBookings.push(bookingObject)
                }
            } else {
                this.selectedBookings.push(bookingObject)
            }
        },
        setBookingTimes() {
            let tempArray = []
            for (let i = 9; i < 18; i++) {
                let tempObj = {
                    fields: {
                        Time: `${i >= 10 ? '' : '0'}${i}:00` + ' - ' + `${i + 1 >= 10 ? '' : '0'}${i + 1}:00`,
                        Occupied: false,
                        GeneratedId: `${i >= 10 ? '' : '0'}${i}:00${this.$moment(this.meetingRoomDate).format('MMDDYYYY')}`
                    }
                }

                tempArray.push(tempObj)
            }
            this.meetingRoomTimes = tempArray
        },
        filterBookingsByMonth(month, data) {
            let tempArray = []
            let filteredData = data.filter((record) => {
                return this.$moment(record.fields.BookingDate).format('MMMM') === this.$moment(month).format('MMMM')
            })
            filteredData.forEach((booking)=>{
                    if(booking.fields.BookingDate !== this.$moment().format('YYYY-MM-DD')){
                        tempArray.push(booking)
                    }
                })
            this.bookings = tempArray
        },
        bookAll() {
            this.meetingRoomTimes.forEach((booking) => {
                if (!this.giveProperClassMeetingRoomBooking(booking).includes('occupied') && !this.checkToDisableBox(booking).includes('cant-select')) {
                    this.toggleBookingMeetingRoom(booking)
                }
            })
        },
        checkToDisableBox(booking) {
            if (this.$moment(this.meetingRoomDate).format('YYYY-MM-DD') !== this.$moment().format('YYYY-MM-DD')) {
                return 'can-select'
            } else {
                if (parseInt(booking.fields.Time.split(' - ')[0].split(':')[0]) > this.currentTime) {
                    return 'can-select'
                } else {
                    return 'cant-select'
                }
            }
        }
    }
}

</script>
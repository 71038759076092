<template>
    <ul class="navbar-nav navbar-menu">
        <li class="nav-item" v-for="(navLink, index) in navLinks" :key="index">
            <span @click="scrollToOrRedirect(navLink.url)">{{navLink.text}}</span>
        </li>
    </ul>
</template>

<script>
import {gsap} from 'gsap'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'NavbarMenu',
    props: {
        navLinks: {
            type: Array,
            required: true,
            validate: (val) => {
                return Array.isArray(val);
            }
        }
    },
    methods: {
        scrollToOrRedirect(link){
            if(link.includes('#')){
                let tempString = ''
                if(link.includes('/')){
                    tempString = link.slice(1)
                }else{
                    tempString = link
                }
                gsap.to(window, {duration: 1, scrollTo:{y: tempString, offsetY: 70}});
            }else{
                this.$router.push(link)
            }
        }
    }
}
</script>
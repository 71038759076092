import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { GraphQLClient } from 'graphql-request';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter from 'vue-router'
import router from "./assets/js/routes";
import VueMoment from 'vue-moment';
import VueMeta from 'vue-meta'
import AOS from 'aos'
import 'aos/dist/aos.css'
import * as rules from 'vee-validate/dist/rules'
import {messages} from 'vee-validate/dist/locale/en.json'
import {ValidationObserver, ValidationProvider, extend} from 'vee-validate'

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.use(VueMoment);
Vue.use(VueMeta);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Object.keys(rules).forEach((rule)=>{
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  })
})

const contentAPI = process.env.VUE_APP_GRAPH_CMS_CONTENT_API

export const graphCmsClient = new GraphQLClient(contentAPI);

Vue.mixin({
  beforeCreate() {
    this.$graphcms = graphCmsClient;
  }
});

new Vue({
  render: h => h(App),
  mounted() {
    AOS.init()
  },
  router
}).$mount('#app')
<template>
    <section class="informative-section-wrapper">
        <div class="informative-section-container" ref="informative-section-container">
            <InformativeSection
                v-for="(informationData, index) in informationSectionMetaData"
                :key="index"
                :title="informationData.title"
                :content="informationData.content"
                :index="index + 1"
                :dataLength="informationSectionMetaData.length"
            />
        </div>
        <div class="button-container">
            <div @click="scrollLeft" class="scroll-left-button">
                <img :src="arrowIconRelativePath" />
            </div>
            <div @click="scrollRight" class="scroll-right-button">
                <img :src="arrowIconRelativePath" />
            </div>
        </div>
    </section>
</template>
<script>

import InformativeSection from "../InformativeSection/informativeSection";

export default {
    name: 'InformativeSectionContainer',
    components: {InformativeSection},
    props: {
        informationSectionMetaData: {
            type: Array,
            required: true,
            validate: (val) => {
                return Array.isArray(val);
            }
        }
    },
    data(){
        return{
            arrowIconRelativePath: require(`../../../assets/img/next.png`) ,
        }
    },
    methods: {
        scrollLeft(){
            this.$refs['informative-section-container'].scrollLeft -= 300
        },
        scrollRight(){
            this.$refs['informative-section-container'].scrollLeft += 300
        }
    }
}
</script>
<template>
    <div class="email-thank-you-page">
        <div class="header">
            <img :src="iconUrl" alt="">
            <p class="title">INCUB&CO</p>
            <p class="small-text">jump startup</p>
        </div>
        <b-container class="small-text confirmation-email">
            <p class="fw-bold">Hey there,</p>
            <p class="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <img :src="imgUrl" alt="" class="w-100" style="height: 127px; margin: 30px 0;">
            <div class="bookings-container" v-for="(booking, index) in bookings" :key="index">
                <div class="booking">
                    <img :src="dateIcon" alt="">
                    <p>{{ $moment(booking.fields.BookingDate).format('MMM DD, YYYY') }}</p>
                </div>
                <div class="booking">
                    <img :src="timeIcon" alt="">
                    <p>{{ booking.fields.Time ? booking.fields.Time : booking.fields.DesksRequested }}</p>
                </div>
                <div class="booking">
                    <img :src="locationIcon" alt="">
                    <p>Route de la Maladière 21, 1022 Chavannes-près-Renens</p>
                </div>
            </div>
            <p class="mt-3">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto.</p>
            <p>Signed,</p>
            <p class=""><span class="fw-bold">Dana Trampe</span> <br> CEO incub&co</p>
        </b-container>
        <div class="footer">
            <div class="image-container">
                <img :src="twitterIconUrl" alt="">
                <img :src="facebookIconUrl" alt="">
                <img :src="instagramIconUrl" alt="">
            </div>
            <p>incub&co.com</p>
            <p>Route de la Maladière 21,<br>
                1022 Chavannes-près-Renens</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ConfirmationEmail',
    data() {
        return {
            twitterIconUrl: require('../../assets/img/Twitter.png'),
            facebookIconUrl: require('../../assets/img/Facebook.png'),
            instagramIconUrl: require('../../assets/img/Instagram.png'),
            locationIcon: require('../../assets/img/Location.png'),
            timeIcon: require('../../assets/img/Time.png'),
            dateIcon: require('../../assets/img/Date.png'),
            iconUrl: require('../../assets/img/icon.png'),
            bookings: [],
            imgUrl: require('../../assets/img/Rectangle 81.png')
        }
    },
    mounted() {
        this.bookings = JSON.parse(localStorage.getItem('bookings'))
    },
    methods: {

    }
}

</script>
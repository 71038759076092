<template>
    <div>
        <Default/>
    </div>
</template>

<script>

import Default from "./layouts/default";

export default {
    name: 'App',
    components: {
        Default
    }
}
</script>
<style lang="scss">
@import "assets/scss/index.scss";
</style>

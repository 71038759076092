<template>
    <div class="coworking-hero-section" id="about">
        <img :src="imgUrl" alt="">
        <div class="section-text-container">
            <p class="bolder-medium-text">{{mainTitle}}</p>
            <div class="text-container" v-html="text"></div>
            <a href="#booking-section">
                <CustomButton button-text="Book"/>
            </a>
        </div>
    </div>
</template>

<script>

import CustomButton from "../global/CustomButton/customButton";
import {getCoworkingHeroSectionContent} from "../../assets/js/services";
export default {
    name: 'CoworkingHeroSection',
    components: {CustomButton},
    data(){
        return{
            mainTitle: '',
            text: '',
            imgUrl: ''
        }
    },
    async created() {
        const response = await getCoworkingHeroSectionContent()
        const data = response.coworkingHeroSections[0]

        this.mainTitle = data.mainTitle
        this.text = data.text.html
        this.imgUrl = data.mainImage.url
    }
}

</script>
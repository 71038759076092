<template>
    <button @click="clickFunction" :class="`${buttonClassName? buttonClassName: ''} ${darkTheme? 'dark-themed':'light-themed'}`" :disabled="loading">
        <p>{{buttonText}}</p>
    </button>
</template>
<script>

export default {
    name: 'CustomButton',
    props:{
        buttonText:{
            type: String,
            required: true
        },
        darkTheme:{
            type: Boolean,
            default: false
        },
        buttonClassName: String,
        loading: Boolean
    },
    methods: {
        clickFunction(){
            this.$emit('onClickFunction')
        }
    }
}
</script>
<template>
    <div>
        <p class="xlarge-text">{{ headerText }}</p>
        <hr class="thick-underline">
    </div>
</template>
<script>

export default {
    name: 'SectionHeader',
    props:{
        headerText:{
            type: String,
            required: true
        }
    }
}
</script>
<template>
    <div class="coworking-benefits">
        <div data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-duration="1000">
            <b-container>
                <div class="text-container">
                    <p class="bolder-medium-text">{{leftSideText}}</p>
                    <p class="small-text text-start" v-html="rightSideText"></p>
                </div>
                <div class="benefits-container" v-if="benefitBoxes.length !== 0">
                    <div class="benefit" v-for="(benefit, index) in benefitBoxes" :key="index">
                        <img :src="benefit.icon.url" alt="">
                        <p class="bolder-medium-text">{{ benefit.mainTitle }}</p>
                        <p class="small-text">{{ benefit.description }}</p>
                    </div>
                </div>
            </b-container>
        </div>
        <CoworkingImageCarousel :carousel-images="carouselImages"/>
    </div>
</template>

<script>

import {getBenefitSectionContent} from "../../assets/js/services";
import CoworkingImageCarousel from "../CoworkingImageCarousel/coworkingImageCarousel";

export default {
    name: 'CoworkingBenefits',
    components: {CoworkingImageCarousel},
    data() {
        return {
            rightSideText: '',
            leftSideText: '',
            benefitBoxes: [],
            carouselImages: []
        }
    },
    async created() {
        const response = await getBenefitSectionContent()
        const data = response.benefitSections[0]
        this.rightSideText = data.rightSideText.replace(/\n/g, "<br/>")
        this.leftSideText = data.leftSideText
        this.benefitBoxes = data.benefitBoxes
        this.carouselImages = data.carouselImage
}
}
</script>